require("@rails/ujs").start()
require("@rails/activestorage").start()
require("jquery")
require("@popperjs/core")
import flatpickr from 'flatpickr';
require("flatpickr/dist/flatpickr.css")


import $ from 'jquery';
window.jQuery = $;
window.$ = $;
// import “bootstrap”


// add these 2 lines
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import '../js/bootstrap_js_files.js'
import "../js/main.js"
import "@fortawesome/fontawesome-free/css/all"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker"
import "../packs/application.scss"
// import { Tooltip, Popover } from "bootstrap"


document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})